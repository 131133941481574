import BasicLayout from '../layout/Basic';
import { Banner } from '../src/components/Common/Banner/Banner';
import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

export default function Custom404() {
  const router = useRouter();
  const lang = router.locale;

  return (
    <BasicLayout>
      <Banner>
        <h1>
          {lang === 'en'
            ? '404 - Page Not Found'
            : '404 - Página no encontrada'}
        </h1>
      </Banner>
    </BasicLayout>
  );
}
export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}
